import React from "react";

export default function Map() {
  return (
    <div
      className="full-screen-container map"
      style={{
        overflow: "hidden",
      }}
    >
      <iframe
        title="Map"
        src="https://www.google.com/maps/d/embed?mid=1ne8Xr2HSWp35IEcyhNUFlxFuGQX9too&ehbc=2E312F&ll=52.366583629284804%2C4.900126771940632&z=13"
        width={480}
        height={1024}
        aria-hidden="false"
        tabIndex={0}
      ></iframe>
    </div>
  );
}
