export enum APPS {
  twitter = "twitter",
  mail = "mail",
  maps = "maps",
  linkedIn = "linkedIn",
  camera = "camera",
  call = "call",
  aboutMe = "aboutMe",
  portfolio = "portfolio",

  // tech
  react = "react",
  reactNative = "reactNative",
  typescript = "typescript",
  sass = "sass",
  nodejs = "nodejs",
  reactQuery = "reactQuery",
  reactThreeFiber = "reactThreeFiber",
  threeJS = "threeJS",
  azureDevOps="azureDevOps",

  // tools
  firebase = "firebase",
  cloudFns = "cloudFns",
  gitHub = "gitHub",
  webpack = "webpack",
  gitHubActions = "gitHubActions",
  vercel = "vercel",

  // testing tools
  jest = "jest",
  cypress = "cypress",
  rtl = "rtl",

  // work
  returnGenie = "returnGenie",
  homeal = "homeal",
  tmo = "tmo",

  // games
  sudoku = "sudoku",
  Game2048 = "2048",
  wordle = "wordle",
}

export const LogoMap: { [k: string]: string } = {
  [APPS.react]: "./apps/react.png",
  [APPS.reactThreeFiber]: "./apps/react-native.png",
  [APPS.reactNative]: "./apps/react-native.png",
  [APPS.typescript]: "./apps/typescript.png",
  [APPS.sass]: "./apps/sass.png",
  [APPS.reactQuery]: "./apps/react-query.png",
  [APPS.threeJS]: "./apps/three-js.png",
  [APPS.nodejs]: "./apps/nodejs.png",
  [APPS.firebase]: "./apps/firebase.jpeg",
  [APPS.cloudFns]: "./apps/cloud-fns.png",
  [APPS.gitHub]: "./apps/github.jpeg",
  [APPS.webpack]: "./apps/webpack.jpeg",
  [APPS.gitHubActions]: "./apps/github-actions.png",
  [APPS.vercel]: "./apps/vercel_light.jpeg",
  [APPS.rtl]: "./apps/rtl.png",
  [APPS.jest]: "./apps/jest.png",
  [APPS.cypress]: "./apps/cypress.jpeg",
  [APPS.returnGenie]: "./apps/return-genie.jpeg",
  [APPS.homeal]: "./apps/homeal.png",
  [APPS.linkedIn]: "./apps/linkedin.png",
  [APPS.twitter]: "./apps/twitter.png",
  [APPS.aboutMe]: "./apps/ks.jpg",
  [APPS.Game2048]: "./apps/2048.png",
  [APPS.sudoku]: "./apps/sudoku.png",
  [APPS.call]: "./apps/apple-call.png",
  [APPS.maps]: "./apps/maps.png",
  [APPS.mail]: "./apps/apple-mail.png",
  [APPS.camera]: "./apps/apple-camera.png",
  [APPS.portfolio]: "./apps/ks.jpg",
  [APPS.tmo]: "./apps/tech-me-out.jpeg",
  [APPS.azureDevOps]: "./apps/azure-devops.png",
};
