import { useState, useEffect, ReactNode } from "react";
import { APPS, LogoMap } from "../models";
import "../scss/SplashScreen.scss";

type SplashScreenProps = {
  children: ReactNode[] | ReactNode;
  color?: string;
  loadingText?: string;
  duration?: number;
  appId?: string;
};

const SplashScreen = ({
  children,
  color = "#fff",
  loadingText,
  appId,
  duration = 500,
}: SplashScreenProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), duration);

    return () => {
      setShow(false);
      clearTimeout(timeout);
    };
  }, [children, duration]);

  return (
    <>
      {show ? (
        <>{children}</>
      ) : (
        <div
          className="splash-screen"
          style={{
            backgroundColor: color,
          }}
        >
          <div className="splash-screen-content">
            {appId && <img src={LogoMap[appId]} alt="logo" />}
            {loadingText && <h1>{loadingText}</h1>}
          </div>
        </div>
      )}
    </>
  );
};

export default SplashScreen;
