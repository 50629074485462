import React from "react";
import { Route, Routes } from "react-router-dom";
import Iphone from "./pages/Iphone";
import Homescreen from "./pages/Homescreen";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Iphone />} />
        <Route path="/homescreen" element={<Homescreen />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
