import React, { useEffect, useState } from "react";
import { APPS, LogoMap } from "../models";
import "../scss/Technologies.scss";

export const TechApps: any = {
  [APPS.react]: {
    name: "react",
    message:
      "I have been working with React.js for my clients and personal projects for the past year and it has been an absolute pleasure. The flexibility and low barrier to entry has made building complex, interactive user interfaces a breeze. I would also appreciate the strong community support and wealth of resources available for learning and troubleshooting.",
  },
  [APPS.reactNative]: {
    name: "reactNative",
    message:
      "I have had the opportunity to work with React Native for a client called Return Genie and I was thoroughly impressed. I was able to use my existing knowledge with React to build the mobile application. The ability to use the same codebase for both iOS and Android platforms has significantly increased my productivity and efficiency.",
  },
  [APPS.typescript]: {
    name: "typescript",
    message:
      "Though I was initially reluctant to use TypeScript, it has greatly improved my development experience. The added type safety and better code organization has made my code more maintainable and easier to understand. After using it for a while now, I have started to appreciate the improved development experience and reduced number of runtime errors.",
  },
  [APPS.reactQuery]: {
    name: "reactQuery",
    message:
      "React Query has been a lifesaver for me. If I was a core member of React, I would have made React Query the official recommended library to handle data fetching and caching in all React projects. The automatic caching and data management has greatly simplified my code and improved the overall performance of my applications. This library handles all the complexities of data management and allows me to focus on building the application logic.",
  },
  [APPS.sass]: {
    name: "sass",
    message:
      "I started to use SASS to style my React projects right from start and it was love at first use. The added functionality and ability to modularize my styles has made my code more maintainable and easier to understand. The nested syntax has also made it easier to understand the structure of my styles and navigate to the right style block to modify.",
  },
  [APPS.threeJS]: {
    name: "threeJs",
    message:
      "After I visited some mind blowing 3d websites. I was curious to build one and I discovered Three.js. After spending some time on the basics of how 3d works in the web, I decided to incorporate my learnings in my portfolio to create a simplistic and unique expericence.",
  },
  [APPS.nodejs]: {
    name: "nodeJs",
    message:
      "When working with one of my clients Return Genie, I had the oppurtunity to modify some cloud functions written in Express. I was pleasantly surprised on how simple it was to create and deploy a rest api. Though I would not consider my self a full-fledged backed developer, I would be interested to get my hands dirty in writing some rest apis with node and express.",
  },

  [APPS.jest]: {
    name: "jest",
    message:
      "I started to use Jest initially as it was included as the part of Create React App, and now Jest has become my go-to testing framework. It's easy to set up and also has a seamless integration with React Testing Library.",
  },
  [APPS.rtl]: {
    name: "rtl",
    message:
      "React Testing Library has made testing my React components so much simpler. It's a great way to test the behavior of your components and how the user will interact with them. With RTL, I can test my components with more confidence, knowing that it closely mimics how the user will use them.",
  },

  [APPS.cypress]: {
    name: "cypress",
    message:
      "Cypress has been a game changer for my end-to-end testing. It is so intuitive to use that I did not even consider to try other alternatives. I love how it helps you to playback any interation in the test just by hovering on the line of code in browser mode.",
  },
  [APPS.firebase]: {
    name: "firebase",
    message:
      "We used Firebase platform to the fullest for Return Genie. We used the Real time database as backend, it's authentication provider for password Authentication and also its cloud function to make some web hook calls. We also used Firebase hosting to host the frontend application and also used its App distribution to release beta apps to specific testers.",
  },
  [APPS.cloudFns]: {
    name: "cloudFns",
    message:
      "I have been using Cloud Functions for my personal projects and with one of clients. The fully managed environment has greatly reduced the complexity and maintenance overhead. Due to its cost effectiveness, I believe I will continue to use cloud functions in my personal projects for a long time.",
  },
  [APPS.webpack]: {
    name: "webpack",
    message:
      "I have been using Webpack to manage the build process of my React projects. Since it is supported out of the box in CRA projects and also there are loaders for almost any file or workflow you need it has been my build tool of choice for a while.",
  },
  [APPS.gitHub]: {
    name: "gitHub",
    message:
      "Gitub is my source-control of choice and after the introduction of Github actions workflows, I have started to like it more.  The ability to easily create and manage workflows for building, testing, and deploying my projects has greatly improved my development workflow.",
  },
  [APPS.vercel]: {
    name: "vercel",
    message:
      "Vercel has made my life so much easier. I wanted to deploy this portfolio in a minimal and cost-effecive way and Vercel has been the answer to my prayers. With its smooth integration with Github, I can focus on writing code instead of dealing with complicated hosting and deployment processes. ",
  },
};

type TechnologiesProps = {
  selectedId: string;
  backgroundColor?: string;
  color?: string;
};

export default function Technologies({
  selectedId,
  backgroundColor = "#241b1c",
  color = "#fff",
}: TechnologiesProps) {
  const { name, message } = TechApps[selectedId] || {};

  return (
    <div className="testimonials-container" style={{ backgroundColor, color }}>
      <div id={selectedId} className="testimonial">
        <div className="logo">
          <img src={LogoMap[selectedId]} alt="logo" />
        </div>

        <section>
          <h3>{name?.toUpperCase()}</h3>
          <div>{message}</div>
        </section>
      </div>
    </div>
  );
}
