import { useState } from "react";
import cn from "classnames";
import "../scss/Folder.scss";

const Folder = ({ label, children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="folder-wrapper">
      <div
        className={cn({ "folder-container": true, open: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="fore-ground"></div>
        {isOpen && label && <h1>{label}</h1>}
        <div className="folder">{children}</div>
      </div>
      {!isOpen && label && <div>{label}</div>}
    </div>
  );
};

export default Folder;
