import { APPS, LogoMap } from "../models";
import "../scss/Work.scss";

const WorkApps: any = {
  [APPS.tmo]: {
    name: "TechMeOut",
    techUsed: [
      APPS.react,
      APPS.typescript,
      APPS.sass,
      APPS.azureDevOps,
      APPS.jest,
      APPS.rtl,
    ],
    workType: "Amsterdam",
    description:
      "TechMeOut is a Resume Builder Platform that helps job seekers to generate resumes dynamically.",
    responsibilites: `Developed features for the Resume Builder UI for the webapp using React.<br>
      Integrated with dynamic PDF renderer that was generated using Latex templates.<br>
      Experimented/developed new improvements for different user journeys.`,
  },
  [APPS.returnGenie]: {
    name: "Return Genie - Pulse It",
    techUsed: [
      APPS.react,
      APPS.reactNative,
      APPS.firebase,
      APPS.cloudFns,
      APPS.jest,
      APPS.rtl,
    ],
    workType: "Singapore – Remote",
    description:
      "Pulse It makes it possible for Online Brands and e-tailers to provide hassle-free returns and instant refunds to consumers.",
    responsibilites: `Developed the ui for the mobile app using React native and the web app using React.<br>
      Integrated payment gateway and implemented payments using Stripe.<br>
      Experimented/developed new features for Retail and Business user journeys.<br>
      Supported/Enhanced the SDLC processes in the team.`,
  },
  [APPS.homeal]: {
    name: "Homeal",
    techUsed: [APPS.react, APPS.typescript, APPS.reactQuery, APPS.cypress],
    workType: "Freelance - Netherlands",
    description:
      "Homeal provides a platform for local cooks by helping them offer their home cooked native meals to customers.",
    responsibilites: `Developed the ui and added new features for the application.<br>
Helped to identify and fix functional bugs that was hindering the functionality of the application.<br>
Collaborated with the designer and review UI designs.`,
  },
  [APPS.portfolio]: {
    name: "My Portfolio",
    techUsed: [
      APPS.react,
      APPS.typescript,
      APPS.threeJS,
      APPS.reactThreeFiber,
      APPS.reactQuery,
      APPS.sass,
      APPS.vercel,
    ],
    workType: "",
    description:
      "A simple portfolio to display my experience and technologies under my toolbelt",
    responsibilites: `Ideated to come up with a simple and eye catchy design.<br>
    Researched on webgl and threejs to include 3d experience for the website.<br>
    Developed the portfolio to showcase the technologies that i use in my daily work.<br>
    Implemented responsive web design so that the application supports various viewports.<br>
    Deployed the application and mapped it to my custom domain.
    `,
  },
};

export const TechLabels: { [s: string]: string } = {
  [APPS.react]: "React",
  [APPS.reactThreeFiber]: "React Three Fiber",
  [APPS.reactNative]: "React Native",
  [APPS.typescript]: "Typescript",
  [APPS.reactQuery]: "React Query",
  [APPS.sass]: "Sass",
  [APPS.threeJS]: "Threejs",
  [APPS.nodejs]: "Nodejs",
  [APPS.jest]: "Jest",
  [APPS.rtl]: "Rtl",
  [APPS.cypress]: "Cypress",
  [APPS.firebase]: "Firebase",
  [APPS.cloudFns]: "Cloud Functions",
  [APPS.webpack]: "Webpack",
  [APPS.gitHub]: "Github",
  [APPS.vercel]: "Vercel",
  [APPS.azureDevOps]: "Azure Devops",
};
type TechnologiesProps = {
  selectedId: string;
  backgroundColor?: string;
  color?: string;
};

export default function Work({
  selectedId,
  backgroundColor = "#241b1c",
  color = "#fff",
}: TechnologiesProps) {
  const { name, techUsed, workType, description, responsibilites } =
    WorkApps[selectedId] || {};

  return (
    <div
      className="full-screen-container work-container"
      style={{ backgroundColor, color }}
    >
      <div id={selectedId} className="work">
        <div className="logo">
          <img src={LogoMap[selectedId]} alt="logo" />
        </div>

        <div className="work-header">
          <div className="work-title">{name?.toUpperCase()}</div>
          {workType && <div className="work-type">({workType})</div>}
        </div>

        <div className="section">
          <div className="section-header">About</div>
          <div className="section-desc">{description}</div>
        </div>
        <div className="section">
          <div className="section-header">Tech used:</div>
          <div className="section-desc tech-tiles-container">
            {techUsed.map((tech: any) => (
              <div className="tech-tile" key={tech}>
                <img src={LogoMap[tech]} alt="tech logo" />
                <div className="tech-label">{TechLabels[tech]}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="section">
          <div className="section-header">Responsibilities</div>
          <div className="section-desc">
            <ul className="responsibilities">
              {responsibilites
                .split("<br>")
                .map((responsibility: string, i: number) => (
                  <li className="responsibilities-item" key={i}>
                    {responsibility}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
