import React, { useState } from "react";
import {
  Environment,
  Float,
  Html,
  PresentationControls,
  useGLTF,
} from "@react-three/drei";
import { Canvas, useThree, Vector3 } from "@react-three/fiber";
import gsap from "gsap";
import { forwardRef, useRef } from "react";
import LogoText from "../components/LogoText";
import cn from "classnames";
import "../scss/Iphone.scss";
import {
  getIphoneVersion,
  IphoneVersion,
  isIosChrome,
  isIphoneModel,
} from "../helpers/iphone-version";

const getIphoneProps = (isChrome = false) => {
  const chromeFactor = isChrome ? 0.09 : 0;
  const iphoneProps = {
    distanceFactor: 2.4 + chromeFactor,
    position: [0.22, 0.475, -0.03],
  };

  switch (getIphoneVersion()) {
    case IphoneVersion.i_12_13_MAX:
      return { ...iphoneProps };
    case IphoneVersion.i_12_13_PLUS:
      return { ...iphoneProps, distanceFactor: 2.42 + chromeFactor };
    case IphoneVersion.i_12_13:
      return { ...iphoneProps, distanceFactor: 2.18 + chromeFactor };
    case IphoneVersion.i_12_13_mini:
      return { ...iphoneProps, distanceFactor: 1.825 + chromeFactor };
    case IphoneVersion.i_XS_11:
      return { ...iphoneProps, distanceFactor: 2.1 + chromeFactor };
    case IphoneVersion.i_XS_11_MAX:
      return { ...iphoneProps, distanceFactor: 2.34 + chromeFactor };

    default:
      return iphoneProps;
  }
};

const Floater = ({ children, ...props }: any) => {
  return window.innerWidth < 800 ? (
    <>{children}</>
  ) : (
    <Float {...props}>{children}</Float>
  );
};

const IphoneModel = forwardRef((_, ref: React.ForwardedRef<unknown>) => {
  const iphone = useGLTF("/model/iphone.gltf");
  const { size } = useThree();
  const [show, setShow] = useState(false);

  const isSmallScreen = size?.height < 1000;

  const options = {
    position: isSmallScreen ? [0, 0.15, 0.01] : [0, 0, 0],
    rotation: [-0.5, 0, 0],
    scale: isSmallScreen ? 3.0 : 3.45,
  };

  const onLoad = () => {
    if (iphone?.scene) {
      setTimeout(() => {
        gsap.to(iphone.scene.rotation, {
          duration: 0.5,
          y: Math.PI,
          onComplete: () => {
            setShow(true);
          },
        });
      }, 200);
    }
  };

  const isIphone = isIphoneModel();
  const isIphoneChrome = isIosChrome();

  const htmlProps = isIphone
    ? getIphoneProps(!!isIphoneChrome)
    : {
        transform: true,
        distanceFactor: 0.37,
        position: [0, 0.0, -0.03],
      };

  return (
    <primitive ref={ref} object={iphone.scene} {...options}>
      <Html
        occlude={[ref as any]}
        wrapperClass="iframe-wrapper"
        rotation={[0, Math.PI, 0]}
        {...(htmlProps as any)}
      >
        <iframe
          title="portfolio"
          src="/homescreen"
          className={cn({ "iframe-window": true, iphone: isIphone, show })}
          onLoad={onLoad}
        />
        <div className="use-portrait-msg">Please tilt your device</div>
      </Html>
    </primitive>
  );
});

export default function Iphone() {
  const iphoneGroup = useRef();
  const isIphone = isIphoneModel();

  const lightProps = {
    color: "#ffffff",
    intensity: 1,
    position: [3, 3, 3] as Vector3,
    distance: 10,
    angle: Math.PI / 4,
    penumbra: 0.5,
    decay: 0.5,
  };

  return (
    <div className="canvas-container">
      <Canvas
        gl={{ antialias: true }}
        dpr={window.devicePixelRatio}
        camera={{
          fov: 45,
          near: 0.1,
          far: 2000,
        }}
      >
        <Environment files={"/model/env.hdr"} />
        <LogoText>{"</> kamalam Sornam"}</LogoText>

        <PresentationControls
          global
          cursor
          rotation={[0.5, 0, 0]}
          polar={isIphone ? [0, 0] : [-0.4, 0.2]}
          azimuth={isIphone ? [0, 0] : [-3, 2.75]}
          config={{ mass: 2, tension: 400 }}
          snap={{ mass: 4, tension: 400 }}
        >
          <>
            <Floater rotationIntensity={0.4}>
              <spotLight {...lightProps} />
              <IphoneModel ref={iphoneGroup} />
            </Floater>
          </>
        </PresentationControls>
      </Canvas>
    </div>
  );
}

useGLTF.preload("/model/iphone.gltf");
