import { useState, useEffect } from "react";
import "../scss/DateTime.scss";

function getDayName() {
  var date = new Date();
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
}

function getTime() {
  return Intl.DateTimeFormat("en-GB", {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date());
}

export const useTime = (refreshCycle = 1000 * 10) => {
  const [now, setNow] = useState(getTime());

  useEffect(() => {
    const intervalId = setInterval(() => setNow(getTime()), refreshCycle);

    return () => clearInterval(intervalId);
  }, [refreshCycle, setNow]);

  return now;
};

const DateTime = () => {
  const date = getDayName();
  const time = useTime();
  return (
    <div className="date-time">
      <div className="date">{date}</div>
      <div className="time">{time}</div>
    </div>
  );
};

export default DateTime;
