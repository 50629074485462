import React, { useState } from "react";
import { FiPhoneMissed, FiInfo } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";
import { HiUserCircle } from "react-icons/hi";
import { IoIosKeypad, IoIosTime } from "react-icons/io";
import { CiVoicemail } from "react-icons/ci";
import "../scss/Calls.scss";

export default function Calls() {
  const [showMsg, setShowMsg] = useState(false);
  return (
    <div className="full-screen-container recents">
      <h1>Recents</h1>
      <div className="recent-item" onClick={() => setShowMsg(!showMsg)}>
        <div className="contact-icon">
          <FiPhoneMissed />
        </div>
        <div className="contact-info">
          <div className="contact-name">Kamalam Sornam</div>
          <div className="contact-type">work</div>
        </div>
        <div className="contact-time">
          <span>yesterday</span>
          <span className="info-icon">
            <FiInfo />
          </span>
        </div>
      </div>
      <div className="call-dock">
        <div className="call-dock-item">
          <div className="call-dock-icon">
            <AiFillStar />
          </div>
          <div className="call-dock-label">Favorites</div>
        </div>
        <div className="call-dock-item highlight">
          <div className="call-dock-icon">
            <IoIosTime />
          </div>
          <div className="call-dock-label">Recents</div>
        </div>
        <div className="call-dock-item">
          <div className="call-dock-icon">
            <HiUserCircle />
          </div>
          <div className="call-dock-label">Contacts</div>
        </div>
        <div className="call-dock-item">
          <div className="call-dock-icon">
            <IoIosKeypad />
          </div>
          <div className="call-dock-label">Keypad</div>
        </div>
        <div className="call-dock-item">
          <div className="call-dock-icon">
            <CiVoicemail />
          </div>
          <div className="call-dock-label">Voicemail</div>
        </div>
      </div>

      {showMsg && (
        <div className="contact-msg">
          For my contact number please message me using the Mail app or send an
          email to{" "}
          <a className="email-link" href="mailto:hey@kamalam.dev">
            hey@kamalam.dev
          </a>
        </div>
      )}
    </div>
  );
}
