import { Ref, useRef } from "react";
import cn from "classnames";
import "../scss/AppTile.scss";

type AppTileProps = {
  src: string;
  label?: string;
  onClick: (prop: {
    ref: Ref<HTMLImageElement>;
    appId: string;
  }) => void;
  appId: string;
  background?: string;
  className?: string;
};

const AppTile = ({
  src,
  label,
  onClick,
  appId,
  background,
  className,
}: AppTileProps) => {
  const ref = useRef(null);

  return (
    <div
      className={cn(["app-tile", className && `${className}`])}
      onClick={(event) => {
        event.stopPropagation();
        if (typeof onClick === "function") {
          onClick({ ref, appId });
        }
      }}
    >
      <img
        alt={`${label} logo`}
        src={src}
        ref={ref}
        style={{
          backgroundColor: background,
        }}
      />
      {label && <div className="icon-label">{label}</div>}
    </div>
  );
};

export default AppTile;
