import React from "react";
import "../scss/AboutMe.scss";

export default function Camera() {
  return (
    <div className="full-screen-container about-me">
      <h1>வணக்கம்</h1>
      "I am Kamalam Sornam, Welcome to my portfolio!. "I am a frontend developer
      with a passion for creating engaging and user-friendly applications.{" "}
      <br />
      <br />
      I live in The Netherlands for the past four years with my husband and kid.
      After taking a break from my career to focus on my kid, I returned back to
      the tech industry in 2022 and helped a couple of clients in developing web
      and mobile applications.
      <br />
      <br />I am a quick learner and have a strong work ethic, and I am eager to
      continue developing my skills and expanding my knowledge.
      <br />
      <br />
      In this portfolio, you will find details about work, my technical skills
      and other details spread across multiple apps.{" "}
      <u>Please feel free to open each app</u> to know more about me and also
      try to find some easter eggs.
      <br />
      <br />
      Thank you for taking the time to visit my portfolio.
      <br />
      <br />
      I am looking forward to the opportunity to discuss my qualifications and
      experience further and find a role where I can make a positive impact.
      <br />
      <br />
      If you would like to contact me use the Mail app to send me a message or
      mail to <a href="mailto:hey@kamalam.dev">hey@kamalam.dev</a>
      <br />
      <br />
      <br />
    </div>
  );
}
