import React from "react";
import { useQuery } from "react-query";
import "../scss/Weather.scss";

const API_PATH =
  "https://api.openweathermap.org/data/2.5/forecast?lat=52.3676&lon=4.9041&appid=42b02ba0ecf227542462dbce34748766&units=metric&cnt=6";

const getWeather = async () => {
  const res = await fetch(API_PATH).then((res) => {
    if (!res.ok) {
      throw new Error("Unable to fettch weather");
    }
    return res.json();
  });

  return res?.list;
};

const useWeather = () => {
  return useQuery<any>("getAmsterdamWeather", getWeather, {
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const getTime = (seconds: number) => {
  const date = new Date(seconds * 1000);
  return date.toLocaleString("en-nl", { hour: "numeric", hour12: true });
};

export default function Weather() {
  const { data, isError, isFetching } = useWeather();

  return (
    <div className="weatherContainer">
      <>
        <div className="row">
          <div className="city">Amsterdam </div>
          <div className="icon">
            {data?.length && (
              <img
                src={`https://openweathermap.org/img/wn/${data[0].weather[0].icon}.png`}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="row temp-container">
          {data?.length && (
            <>
              <div className="temp">{Math.round(data[0].main.temp)}&deg;</div>
              <div className="desc">{data[0].weather[0].description}</div>
            </>
          )}
        </div>

        <div className="forecast-container">
          {isFetching && <div>Fetching weather info...</div>}
          {isError && <div>Unable to get weather info</div>}
          {data?.length &&
            data.slice(1).map((item: any) => (
              <div className="forecast" key={item.dt}>
                <div className="forcase-time">{getTime(item.dt)}</div>
                <div className="icon">
                  <img
                    src={`https://openweathermap.org/img/wn/${item.weather[0].icon}.png`}
                    alt=""
                  />
                </div>
                <div className="desc">{Math.round(item.main.temp)}&deg;</div>
              </div>
            ))}
        </div>
      </>
    </div>
  );
}
