import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { IoIosClose } from "react-icons/io";
import "../scss/Camera.scss";

const videoConstraints = {
  aspectRatio: window.screen.width > 750 ? 0.46875 : undefined,
  facingMode: "user",
};
export default function Camera() {
  const webcamRef = useRef<Webcam | null>(null);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [isCameraSupported, setIsCameraSupported] = useState(true);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setImage(imageSrc);
    }
  }, [webcamRef]);

  useEffect(() => {
    async function getMedia() {
      let stream = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setIsCameraSupported(true);
      } catch (err) {
        setIsCameraSupported(false);
      }
    }

    getMedia();
  }, []);

  return (
    <div className="camera-container">
      {!isCameraSupported && (
        <h1 className="camera-error">
          Oops no photo for you. Time to rethink your loyalty for your browser{" "}
        </h1>
      )}
      <Webcam
        ref={webcamRef}
        height={"1024px"}
        mirrored={true}
        audio={false}
        imageSmoothing={true}
        videoConstraints={videoConstraints}
      />
      <div className="shutter-btn-container">
        <button className={"shutter-btn"} onClick={capture}></button>
      </div>
      {image && (
        <div onClick={() => setShow(true)} className={"capture-thumbnail"}>
          <img src={image} alt="capture" />
        </div>
      )}
      {image && show && (
        <div className="capture-preview">
          <img height="100%" src={image} alt="capture" />
          <button className="close-preview" onClick={() => setShow(false)}>
            <IoIosClose size={"100%"} />
          </button>
          <div className="disclaimer">
            The captured image is not saved and will be discarded when you close
            the app
          </div>
        </div>
      )}
    </div>
  );
}
