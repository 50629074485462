import React, { Ref, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { FiSend } from "react-icons/fi";
import { RiChatSmileLine } from "react-icons/ri";
import ReCAPTCHA from "react-google-recaptcha";
import "../scss/ContactMe.scss";

const ReCaptchaSiteKey = "6LdJQDckAAAAAF4JzslV-j2Le3SW8Q1JrB-li3kq";

export default function ContactMe() {
  const recaptchaRef = React.createRef();
  const [state, handleSubmit] = useForm("myyazrnp");
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (state.succeeded) {
    return (
      <div className="full-screen-container flex-container">
        <h1
          className="message-success"
          style={{
            padding: "1rem",
          }}
        >
          <div className="chat-icon">
            <RiChatSmileLine />
          </div>
          Thanks for your message!!! <br /> I will check and get back to you .
        </h1>
        ;
      </div>
    );
  }

  return (
    <div className="full-screen-container">
      <form
        className="contact-form"
        onSubmit={(event) => {
          setIsSubmitted(true);
          handleSubmit(event);
        }}
      >
        <h1>Contact me</h1>
        <div>
          <b>To: </b>
          <a className="email-link" href="mailto:hey@kamalam.dev">
            hey@kamalam.dev
          </a>
        </div>
        <input
          id="email"
          type="email"
          name="email"
          required
          placeholder="Email"
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />

        <textarea
          id="message"
          name="message"
          required
          placeholder="Type your message"
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <ReCAPTCHA
          id="recaptcha"
          ref={recaptchaRef as Ref<ReCAPTCHA>}
          sitekey={ReCaptchaSiteKey}
          onChange={(captcha: string | null) => setIsCaptchaValid(!!captcha)}
        />

        {isSubmitted && !isCaptchaValid && (
          <div style={{ color: "red" }}>Invalid Captcha</div>
        )}

        <button type="submit" disabled={state.submitting}>
          <span>Send</span>
          <span className="mail-icon">
            <FiSend />
          </span>
        </button>
      </form>
    </div>
  );
}
