import { Text } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { ReactNode } from "react";

type LogoTextProps = {
  children: ReactNode[] | ReactNode;
  color?: string;
  size?: number;
};

const CustomText = (props: any) => <Text {...props} />;

const LogoText = ({
  children,
  size = 1,
  color = "#fff",
  ...props
}: LogoTextProps) => {
  const { viewport } = useThree();

  return (
    <group {...props}>
      <CustomText
        color={color}
        font="./fonts/bangers-v20-latin-regular.woff"
        fontSize={0.15}
        position={[-viewport.width / 2 + 0.65, viewport.height / 2 - 0.2, 0]}
        maxWidth={2}
      >
        {children}
      </CustomText>
    </group>
  );
};
export default LogoText;
