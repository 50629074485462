import React from "react";
import Webcam from "react-webcam";
import { Follow } from "react-twitter-widgets";
import "../scss/Twitter.scss";

export default function Camera() {
  return (
    <div className="full-screen-container twitter-container">
      <img className="profile-pic" src="./dp.jpeg" alt="twitter profile pic" />
      <h1>Follow me on twitter @nskala10</h1>
      <Follow username="nskala10" options={{ size: "large" }} />
    </div>
  );
}
