import React, { Component } from "react";
import { List, Range } from "immutable";
import { solvepuzzle, makepuzzle } from "sudoku";
import { Sudoku } from "./SudokuHelper.js";
import { BiUndo, BiRedo } from "react-icons/bi";
import { GiConvergenceTarget } from "react-icons/gi";

import "./sudoku.css";

const partitionList = (immutableList) => (partitionSize) => {
  if (immutableList.size < partitionSize) {
    return immutableList;
  } else {
    return List([
      immutableList.take(partitionSize),
      ...partitionList(immutableList.skip(partitionSize))(partitionSize),
    ]);
  }
};

const convertToSudokuFromPuzzle = (puzzle) =>
  Sudoku(partitionList(List(puzzle))(9)).sudokuMap((x) => {
    if (x === null) return x;
    return x + 1;
  });

class SudokuGame extends Component {
  selectedInput = {
    current: null,
  };
  constructor() {
    super();
    const puzzle = makepuzzle();
    const solvedPuzzle = solvepuzzle(puzzle);
    const newSudokuTemplate = convertToSudokuFromPuzzle(puzzle);
    const solvedSudoku = convertToSudokuFromPuzzle(solvedPuzzle);
    this.state = {
      history: List([newSudokuTemplate]),
      pointInHistory: -1, //negative indices in '.get' method of 'List' in immutableJS count from end of the list e.g. List([0,1,2,3]).get(-1) returns 3
      isForUserInput: Sudoku(newSudokuTemplate.sudokuMap((x) => x === null)),
      isInCollision: newSudokuTemplate.collisions(),
      solvedSudoku,
    };
  }

  handleStepForward = () => {
    const { pointInHistory: now, history } = this.state;
    const newPointInHistory = now === -1 ? now : now + 1;
    this.setState({
      pointInHistory: newPointInHistory,
      isInCollision: history.get(newPointInHistory).collisions(),
    });
  };

  handleStepBack = () => {
    const { pointInHistory: now, history } = this.state;
    const historySize = history.size;
    const newPointInHistory = now <= -historySize ? now : now - 1;
    this.setState({
      pointInHistory: newPointInHistory,
      isInCollision: history.get(newPointInHistory).collisions(),
    });
  };

  handleInput = (i) => (j) => (value) => {
    const validValues = Range(1, 10);
    const valueAsInt = parseInt(value, 10);
    if (validValues.includes(valueAsInt)) {
      const { history, pointInHistory: now } = this.state;
      const sudokuAfterInput = history
        .get(now)
        .transposeBoxesToRows()
        .setField(
          i,
          j
        )(valueAsInt)
        .transposeRowsToBoxes();
      const newHistory = history.skipLast(-(now + 1)).push(sudokuAfterInput);
      this.setState({
        history: newHistory,
        isInCollision: sudokuAfterInput.collisions(),
        pointInHistory: -1,
      });
    }
  };

  handleNewGame = () => {
    const puzzle = makepuzzle();
    const solvedPuzzle = solvepuzzle(puzzle);
    const newSudokuTemplate = convertToSudokuFromPuzzle(puzzle);
    const solvedSudoku = convertToSudokuFromPuzzle(solvedPuzzle);
    this.setState({
      history: List([newSudokuTemplate]),
      pointInHistory: -1,
      isForUserInput: Sudoku(newSudokuTemplate.sudokuMap((x) => x === null)),
      isInCollision: newSudokuTemplate.collisions(),
      solvedSudoku,
    });
  };

  handleSolveGame = () => {
    const { history, solvedSudoku } = this.state;
    this.setState({
      history:
        history.get(-1) === solvedSudoku ? history : history.push(solvedSudoku),
      pointInHistory: -1,
      isInCollision: solvedSudoku.collisions(),
    });
  };

  render() {
    return (
      <div className="full-screen-container sudoku-board">
        <div className="game-logo">
          <img src="./sudoku-banner.jpg" alt="" />
        </div>
        <div className="sudoku-header">
          <button onClick={this.handleNewGame} id={"newGame"}>
            New Game
          </button>
        </div>
        <SudokuBoard
          board={this.state.history.get(this.state.pointInHistory)}
          isForUserInput={this.state.isForUserInput}
          isInCollision={this.state.isInCollision}
          handleInput={this.handleInput}
          selectedInput={this.selectedInput}
        />

        <div className="sudoku-controls">
          <button onClick={this.handleStepBack}>
            <BiUndo /> Undo
          </button>
          <button onClick={this.handleStepForward}>
            <BiRedo /> Redo
          </button>
          <button onClick={this.handleSolveGame}>
            <GiConvergenceTarget /> Solve
          </button>
        </div>

        <div className="sudoku-inputs">
          {[...Array(9)].map((_, index) => (
            <button
              key={index}
              onClick={() => {
                if (this.selectedInput.current) {
                  this.selectedInput.callback(index + 1);
                }
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

function SudokuBoard(props) {
  const renderRowOfBoxes = (rowNum) => {
    const renderBox = (boxNum) => {
      const boxIndex = 3 * rowNum + boxNum;

      return (
        <SudokuBox
          boxIndex={boxIndex}
          box={props.board.getBox(boxIndex)}
          handleInput={props.handleInput(boxIndex)}
          selectedInput={props.selectedInput}
          isForUserInput={props.isForUserInput.getBox(boxIndex)}
          isInCollision={props.isInCollision.getBox(boxIndex)}
        />
      );
    };

    return (
      <div id={"boxRow" + rowNum} className="boxRow">
        {renderBox(0)}
        {renderBox(1)}
        {renderBox(2)}
      </div>
    );
  };

  return (
    <div id="SudokuBoard">
      {renderRowOfBoxes(0)}
      {renderRowOfBoxes(1)}
      {renderRowOfBoxes(2)}
    </div>
  );
}

function SudokuBox(props) {
  const renderRowOfFields = (rowNum) => {
    const renderSudokuField = (fieldNum) => {
      const fieldIndex = rowNum * 3 + fieldNum;
      return (
        <SudokuField
          boxIndex={props.boxIndex}
          fieldIndex={fieldIndex}
          value={props.box.get(fieldIndex)}
          isForUserInput={props.isForUserInput.get(fieldIndex)}
          isInCollision={props.isInCollision.get(fieldIndex)}
          handleInput={props.handleInput(fieldIndex)}
          selectedInput={props.selectedInput}
        />
      );
    };

    return (
      <div className={`fieldRow${rowNum} fieldRow`}>
        {renderSudokuField(0)}
        {renderSudokuField(1)}
        {renderSudokuField(2)}
      </div>
    );
  };

  return (
    <span className="SudokuBox" id={`box${props.boxIndex}`}>
      {renderRowOfFields(0)}
      {renderRowOfFields(1)}
      {renderRowOfFields(2)}
    </span>
  );
}

function SudokuField(props) {
  const { value, boxIndex, fieldIndex, handleInput, isForUserInput } = props;
  const renderField = function () {
    if (props.isInCollision) {
      return (
        <div
          className={`SudokuField box${boxIndex} field${fieldIndex} collided ${
            isForUserInput ? "userInputField" : "gameOutputField"
          }`}
        >
          {value}
        </div>
      );
    }
    if (!isForUserInput) {
      return (
        <div
          className={`SudokuField box${boxIndex} field${fieldIndex} gameOutputField`}
        >
          {value}
        </div>
      );
    }
    if (isForUserInput && value !== null) {
      return (
        <div
          className={`SudokuField box${boxIndex} field${fieldIndex} userInputField`}
        >
          {value}
        </div>
      );
    }

    return (
      <div
        className={`SudokuField box${boxIndex} field${fieldIndex} userInputField`}
        tabIndex="0"
        onClick={(event) => {
          const current = props.selectedInput.current;
          if (current) {
            current.classList.remove("selected");
            props.selectedInput.callback = () => {};
          }
          event.target.classList.add("selected");
          props.selectedInput.current = event.target;
          props.selectedInput.callback = handleInput;
        }}
      >
        <input
          placeholder="-"
          type="text"
          inputMode="numeric"
          min="0"
          max="9"
          maxLength="1"
          disabled
          onKeyUp={(event) => {
            if (parseInt(event.key)) {
              handleInput(event.key);
            }
          }}
        ></input>
      </div>
    );
  };

  return renderField();
}

export default SudokuGame;
