import { MutableRefObject, useRef, useState } from "react";
import { BsBatteryHalf } from "react-icons/bs";
import { MdSignalCellularAlt, MdWifi } from "react-icons/md";
import { Analytics } from "@vercel/analytics/react";

import cn from "classnames";
import { QueryClient, QueryClientProvider } from "react-query";
import AppLauncher from "../components/AppLauncher";
import AppTile from "../components/AppTile";
import DateTime from "../components/DateTime";
import Folder from "../components/Folder";
import Weather from "../components/Weather";
import { APPS, LogoMap } from "../models";
import "../scss/Homescreen.scss";

const queryClient = new QueryClient();

export default function Homescreen() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeApp, setActiveApp] = useState("");
  const isVisited = localStorage.getItem("aboutMe") === "true";
  const modal: MutableRefObject<any> = useRef(null);

  const toggleModal = (visibility: boolean) => {
    setTimeout(() => {
      setIsOpen(visibility);
    }, 200);
  };

  const openApp = ({ ref, appId }: any) => {
    setActiveApp(appId);
    const iconRect = ref.current.getBoundingClientRect();
    if (modal.current?.style) {
      modal.current.style.transform = `translate3d(${iconRect.left}px, ${iconRect.top}px, 0) scale(0.20, 0.1)`;
    }
    toggleModal(true);
  };

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="homescreen-container">
          <div className="nav-bar">
            <div className="nav-bar-left"></div>
            <div
              className={cn("nav-bar-pill", activeApp && !isOpen && "expand")}
            >
              {activeApp && !isOpen && (
                <img src={LogoMap[activeApp]} alt="pill-logo" />
              )}
            </div>
            <div className="nav-bar-right">
              <MdSignalCellularAlt />
              <MdWifi />
              <BsBatteryHalf />
            </div>
          </div>
          <DateTime />
          <Weather />
          <div className="apps app-container">
            <Folder label="Work">
              <AppTile
                src={LogoMap[APPS.tmo]}
                label="TechMeOut"
                onClick={openApp}
                appId={APPS.tmo}
              />
              <AppTile
                src={LogoMap[APPS.returnGenie]}
                label="Return Genie"
                onClick={openApp}
                appId={APPS.returnGenie}
              />
              <AppTile
                src={LogoMap[APPS.homeal]}
                label={"Homeal"}
                appId={APPS.homeal}
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.portfolio]}
                label={"KS Portfolio"}
                appId={APPS.portfolio}
                onClick={openApp}
              />
            </Folder>

            <Folder label="Tech">
              <AppTile
                src={LogoMap[APPS.react]}
                label="React"
                appId={APPS.react}
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.reactNative]}
                label="React Native"
                appId={APPS.reactNative}
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.typescript]}
                label="Typescript"
                appId={APPS.typescript}
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.sass]}
                appId={APPS.sass}
                label="Sass"
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.reactQuery]}
                appId={APPS.reactQuery}
                label="React Query"
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.threeJS]}
                label="ThreeJs"
                background={"#fff"}
                appId={APPS.threeJS}
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.nodejs]}
                appId={APPS.nodejs}
                label="NodeJs"
                onClick={openApp}
              />
            </Folder>

            <Folder label="Tools">
              <AppTile
                src={LogoMap[APPS.firebase]}
                label="Firebase"
                onClick={openApp}
                appId={APPS.firebase}
              />
              <AppTile
                src={LogoMap[APPS.cloudFns]}
                label="Cloud Functions"
                appId={APPS.cloudFns}
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.gitHub]}
                label="Github"
                appId={APPS.gitHub}
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.webpack]}
                appId={APPS.webpack}
                label="Webpack"
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.vercel]}
                appId={APPS.vercel}
                label="Vercel"
                onClick={openApp}
              />
            </Folder>

            <Folder label="Testing">
              <AppTile
                src={LogoMap[APPS.jest]}
                label="Jest"
                onClick={openApp}
                appId={APPS.jest}
              />
              <AppTile
                src={LogoMap[APPS.rtl]}
                appId={APPS.rtl}
                label="Testing Library"
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.cypress]}
                label="Cypress"
                appId={APPS.cypress}
                onClick={openApp}
              />
            </Folder>

            <AppTile
              src={LogoMap[APPS.linkedIn]}
              label="LinkedIn"
              onClick={openApp}
              className="normalise-size"
              appId={APPS.linkedIn}
            />
            <AppTile
              src={LogoMap[APPS.twitter]}
              label="Twitter"
              onClick={openApp}
              appId={APPS.twitter}
            />
            <AppTile
              src={LogoMap[APPS.aboutMe]}
              label="About Me"
              className={cn({ blink: !isVisited })}
              appId={APPS.aboutMe}
              onClick={({ ref, appId }: any) => {
                localStorage.setItem("aboutMe", "true");
                ref?.current?.classList.remove("blink");
                openApp({ ref, appId });
              }}
            />

            <Folder label="Games">
              <AppTile
                src={LogoMap[APPS.Game2048]}
                label={"2048"}
                onClick={openApp}
                appId={APPS.Game2048}
              />
              <AppTile
                label="Sudoku"
                src={LogoMap[APPS.sudoku]}
                onClick={openApp}
                appId={APPS.sudoku}
              />
            </Folder>
          </div>

          <div className="dock">
            <div className="apps">
              <AppTile
                src={LogoMap[APPS.call]}
                onClick={openApp}
                appId={APPS.call}
              />
              <AppTile
                src={LogoMap[APPS.maps]}
                appId={APPS.maps}
                className="normalise-size"
                onClick={openApp}
              />
              <AppTile
                src={LogoMap[APPS.mail]}
                onClick={openApp}
                appId={APPS.mail}
              />
              <AppTile
                src={LogoMap[APPS.camera]}
                onClick={openApp}
                appId={APPS.camera}
              />
            </div>
          </div>
          <AppLauncher
            ref={modal}
            activeApp={activeApp}
            isOpen={isOpen}
            onClose={() => {
              toggleModal(false);
              setTimeout(() => {
                setActiveApp("");
              }, 500);
            }}
          />
        </div>
      </QueryClientProvider>
      <Analytics />
    </>
  );
}
