import React from "react";
import { BiUserPlus } from "react-icons/bi";
import "../scss/LinkedIn.scss";

export default function LinkedIn() {
  return (
    <div className="full-screen-container linkedin-container">
      <a
        href="https://www.linkedin.com/in/kamalam-sornam-70ba66241/"
        target="blank"
        className="connect-btn"
      >
        <BiUserPlus />
        Connect
      </a>
      <img width={"100%"} src="./linkedin.png" alt="" />
    </div>
  );
}
