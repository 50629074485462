import { Range } from "immutable";

const zip = (xs, ...xss) => xs.zip(...xss);

const isDuplicitValue = (value) => (list) =>
  list.indexOf(value) !== list.lastIndexOf(value);

const getRow = (sudoku) => (i) => sudoku.get(i);

const getColumn = (sudoku) => (j) => sudoku.map((x) => x.get(j));

const getBox = (sudoku) => (i) =>
  sudoku
    .skip(Math.trunc(i / 3) * 3)
    .take(3)
    .map((x) => x.skip((i % 3) * 3).take(3))
    .reduce((x, y) => x.concat(y));

const getField = (sudoku) => (i, j) => sudoku.get(i).get(j);

const setField = (sudoku) => (fieldRow, fieldColumn) => (value) =>
  Sudoku(sudoku.set(fieldRow, sudoku.get(fieldRow).set(fieldColumn, value)));

const transposeBoxesToRows = (sudoku) =>
  Sudoku(
    Range(0, Infinity)
      .take(9)
      .toList()
      .map((x) => sudoku.getBox(x))
  );

const transposeRowsToBoxes = transposeBoxesToRows;

const transposeColumnsToRows = (sudoku) =>
  Sudoku(
    Range(0, Infinity)
      .take(9)
      .toList()
      .map((x) => sudoku.getColumn(x))
  );

const transposeRowsToColumns = transposeColumnsToRows;

const sudokuMap = (sudoku) => (f) => Sudoku(sudoku.map((list) => list.map(f)));

const rowColisions = (sudoku) =>
  Sudoku(
    sudoku.map((row) =>
      row.map((field) =>
        isDuplicitValue(field)(row.filter((field) => field !== null))
      )
    )
  );

const sudokuZip = (...sudokus) =>
  Sudoku(zip(...sudokus).map((zippedRows) => zip(...zippedRows)));

const collisions = (sudoku) =>
  Sudoku(
    sudokuZip(
      Sudoku(rowColisions(sudoku)),
      Sudoku(transposeRowsToBoxes(rowColisions(transposeBoxesToRows(sudoku)))),
      Sudoku(
        transposeRowsToColumns(rowColisions(transposeColumnsToRows(sudoku)))
      )
    ).sudokuMap((zippedFields) => zippedFields.some((field) => field === true))
  );

const Sudoku = function (listOfLists) {
  const ImmutableSudoku = Object.create(listOfLists);

  ImmutableSudoku.getRow = getRow(ImmutableSudoku);
  ImmutableSudoku.getColumn = getColumn(ImmutableSudoku);
  ImmutableSudoku.getBox = getBox(ImmutableSudoku);
  ImmutableSudoku.getField = getField(ImmutableSudoku);
  ImmutableSudoku.transposeBoxesToRows = () =>
    transposeBoxesToRows(ImmutableSudoku);
  ImmutableSudoku.transposeRowsToBoxes = () =>
    transposeRowsToBoxes(ImmutableSudoku);
  ImmutableSudoku.transposeColumnsToRows = () =>
    transposeColumnsToRows(ImmutableSudoku);
  ImmutableSudoku.transposeRowsToColumns = () =>
    transposeRowsToColumns(ImmutableSudoku);
  ImmutableSudoku.setField = setField(ImmutableSudoku);
  ImmutableSudoku.sudokuMap = sudokuMap(ImmutableSudoku);
  ImmutableSudoku.collisions = () => collisions(ImmutableSudoku);

  return ImmutableSudoku;
};

export {
  Sudoku,
  getRow,
  getColumn,
  getBox,
  getField,
  setField,
  transposeBoxesToRows,
  sudokuMap,
};

export default Sudoku;
