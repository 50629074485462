/* eslint-disable react-hooks/rules-of-hooks */

import { useEffect, useMemo } from "react";
import {
  AiOutlineArrowDown,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
} from "react-icons/ai";
import Board from "./game";
import "./2048.css";

const game2048 = () => {
  const board = useMemo(() => new Board(), []);

  useEffect(() => {
    board.load();
  }, [board]);

  return (
    <>
      <div className="full-screen-container board">
        <div className="header">
          <div className="score-container">
            <div className="label">Score</div>
            <div className="score" id="score"></div>
          </div>
          <div>
            <button className="new-game-btn" onClick={board.load}>
              New Game
            </button>
          </div>
        </div>

        <center>
          <div id="board-2048"></div>
          <div className="game-over" id="game-over">
            <div className="game-over-msg">Game Over!</div>
            <button className="new-game-btn" onClick={board.load}>
              Try again
            </button>
          </div>
        </center>

        <div className="controls">
          <button onClick={board.up}>
            <AiOutlineArrowUp />
          </button>
          <div>
            <button onClick={board.left}>
              {" "}
              <AiOutlineArrowLeft />
            </button>
            <button onClick={board.down}>
              {" "}
              <AiOutlineArrowDown />
            </button>
            <button onClick={board.right}>
              {" "}
              <AiOutlineArrowRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default game2048;
