import { forwardRef, Ref, useState } from "react";
import { useSwipeable } from "react-swipeable";

import Board2048 from "../games/2048";
import Sudoku from "../games/Sudoku";
import { APPS } from "../models";
import AboutMe from "./AboutMe";
import Calls from "./Calls";
import Camera from "./Camera";
import ContactMe from "./ContactMe";
import LinkedIn from "./LinkedIn";
import Map from "./Map";
import SplashScreen from "./SplashScreen";
import Technologies, { TechApps } from "./Technologies";
import Twitter from "./Twitter";
import Work from "./Work";
import "../scss/AppLauncher.scss";

type SwitchProps = {
  children: any[];
  condition: string;
};
const Switch = ({ condition, children }: SwitchProps) =>
  children?.find((child) => child?.props?.case === condition);

type AppLauncherProps = {
  activeApp: string;
  isOpen: boolean;
  onClose: () => void;
  closeBarColor?: string;
};

const AppLauncher = forwardRef(
  (
    { activeApp, isOpen, onClose, closeBarColor = "#000" }: AppLauncherProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [hasSwiped, setHasSwiped] = useState(
      localStorage.getItem("swipeToClose") === "true"
    );
    const config = {
      delta: 10, // min distance(px) before a swipe starts. *See Notes*
      preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
      trackTouch: true, // track touch input
      trackMouse: true, // track mouse input
      rotationAngle: 0, // set a rotation angle
      swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
      touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    };
    const handlers = useSwipeable({
      onSwipedUp: () => {
        if (!hasSwiped) {
          localStorage.setItem("swipeToClose", "true");
        }
        setHasSwiped(true);
        onClose();
      },
      ...config,
    });
    return (
      <div ref={ref} className={`modal-container ${isOpen ? "open" : ""}`}>
        <div className="app-content">
          <Switch condition={activeApp}>
            <div case={APPS.linkedIn}>
              <SplashScreen color={"#0866c2"} appId={APPS.linkedIn}>
                <LinkedIn />
              </SplashScreen>
            </div>
            <div case={APPS.twitter}>
              <SplashScreen
                appId={APPS.twitter}
                color={"#3e91ce"}
                duration={600}
              >
                <Twitter />
              </SplashScreen>
            </div>
            <div case={APPS.maps}>
              <SplashScreen appId={APPS.maps} color={"#fff"} duration={600}>
                <Map />
              </SplashScreen>
            </div>
            <div case={APPS.mail}>
              <SplashScreen appId={APPS.mail} color={"#169ef8"} duration={600}>
                <ContactMe />
              </SplashScreen>
            </div>
            <div case={APPS.call}>
              <SplashScreen appId={APPS.call} color={"#3ee45c"} duration={600}>
                <Calls />
              </SplashScreen>
            </div>
            <div case={APPS.camera}>
              <Camera />
            </div>

            {/* Technologies */}
            <div case={APPS.react}>
              <SplashScreen appId={APPS.react} color={"#53c1de"} duration={600}>
                <Technologies selectedId={APPS.react} />
              </SplashScreen>
            </div>
            <div case={APPS.reactNative}>
              <SplashScreen
                appId={APPS.reactNative}
                color={"#0f1829"}
                duration={600}
              >
                <Technologies selectedId={APPS.reactNative} />
              </SplashScreen>
            </div>
            <div case={APPS.typescript}>
              <SplashScreen
                appId={APPS.typescript}
                color={"#007acc"}
                duration={600}
              >
                <Technologies selectedId={APPS.typescript} />
              </SplashScreen>
            </div>
            <div case={APPS.sass}>
              <SplashScreen
                appId={APPS.sass}
                color={"#cd679970"}
                duration={600}
              >
                <Technologies selectedId={APPS.sass} />
              </SplashScreen>
            </div>

            <div case={APPS.reactQuery}>
              <SplashScreen
                appId={APPS.reactQuery}
                color={"#ff4154"}
                duration={600}
              >
                <Technologies selectedId={APPS.reactQuery} />
              </SplashScreen>
            </div>

            <div case={APPS.threeJS}>
              <SplashScreen appId={APPS.threeJS} color={"#fff"} duration={600}>
                <Technologies selectedId={APPS.threeJS} />
              </SplashScreen>
            </div>

            <div case={APPS.nodejs}>
              <SplashScreen appId={APPS.nodejs} color={"#000"} duration={600}>
                <Technologies selectedId={APPS.nodejs} />
              </SplashScreen>
            </div>

            <div case={APPS.firebase}>
              <SplashScreen
                appId={APPS.firebase}
                color={"#1a74e9"}
                duration={600}
              >
                <Technologies selectedId={APPS.firebase} />
              </SplashScreen>
            </div>

            <div case={APPS.cloudFns}>
              <SplashScreen
                appId={APPS.cloudFns}
                color={"#3d7ce3"}
                duration={600}
              >
                <Technologies selectedId={APPS.cloudFns} />
              </SplashScreen>
            </div>

            <div case={APPS.gitHub}>
              <SplashScreen
                appId={APPS.gitHub}
                color={"#fff"}
                loadingText={"Github"}
                duration={600}
              >
                <Technologies selectedId={APPS.gitHub} />
              </SplashScreen>
            </div>

            <div case={APPS.webpack}>
              <SplashScreen
                appId={APPS.webpack}
                color={"#fff"}
                loadingText={"Webpack"}
                duration={600}
              >
                <Technologies selectedId={APPS.webpack} />
              </SplashScreen>
            </div>
            <div case={APPS.vercel}>
              <SplashScreen appId={APPS.vercel} color={"#000"}>
                <Technologies selectedId={APPS.vercel} />
              </SplashScreen>
            </div>
            <div case={APPS.jest}>
              <SplashScreen appId={APPS.jest} color={"#c63d15"} duration={600}>
                <Technologies selectedId={APPS.jest} />
              </SplashScreen>
            </div>
            <div case={APPS.rtl}>
              <SplashScreen appId={APPS.rtl} color={"#f5403f"} duration={600}>
                <Technologies selectedId={APPS.rtl} />
              </SplashScreen>
            </div>
            <div case={APPS.cypress}>
              <SplashScreen
                appId={APPS.cypress}
                color={"#4b6971"}
                duration={600}
              >
                <Technologies selectedId={APPS.cypress} />
              </SplashScreen>
            </div>
            <div case={APPS.returnGenie}>
              <SplashScreen
                appId={APPS.returnGenie}
                color={"#312b5e"}
                loadingText={"Return Genie"}
                duration={600}
              >
                <Work selectedId={APPS.returnGenie} backgroundColor="#312b5e" />
              </SplashScreen>
            </div>

            <div case={APPS.tmo}>
              <SplashScreen
                appId={APPS.tmo}
                color={"#5856e9"}
                loadingText={"TechMeOut"}
                duration={600}
              >
                <Work selectedId={APPS.tmo} backgroundColor="#5856e9" />
              </SplashScreen>
            </div>
            <div case={APPS.homeal}>
              <SplashScreen
                appId={APPS.homeal}
                color={"#04b091"}
                loadingText={"homeal"}
                duration={600}
              >
                <Work selectedId={APPS.homeal} backgroundColor="#04b091" />
              </SplashScreen>
            </div>
            <div case={APPS.portfolio}>
              <SplashScreen
                appId={APPS.portfolio}
                color={"#C65875"}
                duration={600}
              >
                <Work selectedId={APPS.portfolio} backgroundColor="#C65875" />
              </SplashScreen>
            </div>

            <div case={APPS.aboutMe}>
              <SplashScreen
                appId={APPS.aboutMe}
                color={"#C65875"}
                duration={600}
              >
                <AboutMe />
              </SplashScreen>
            </div>

            <div case={APPS.Game2048}>
              <SplashScreen
                appId={APPS.Game2048}
                color={"#c1b19a"}
                duration={600}
              >
                <Board2048 />
              </SplashScreen>
            </div>
            <div case={APPS.sudoku}>
              <SplashScreen appId={APPS.sudoku} color={"#000"} duration={600}>
                <Sudoku />
              </SplashScreen>
            </div>
          </Switch>
        </div>

        <div
          className="close-container"
          {...handlers}
          style={{
            color: TechApps[activeApp] ? "#fff" : "#000",
          }}
        >
          {!hasSwiped && <div className="close-text">Swipe up to close</div>}
          <div
            className="close-bar"
            style={{
              borderColor: TechApps[activeApp] ? "#fff" : "#000",
            }}
          ></div>
        </div>
      </div>
    );
  }
);

export default AppLauncher;
